/** React/Utils */
import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

/** Material UI */
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import useMemoTranslations from '../../../../../../hooks/useMemoTranslations';
import useSnacks from '../../../../../../hooks/useSnacks';
import returnAsNewGiftCardMutation from '../../../../../../mutations/returnAsNewGiftCard.mutation';
import AddressForm from '../../../../../address/addressForm';
import NameAndEmailForm from '../../../../../address/nameAndEmailForm';
import Loading from '../../../../../shared/loading';
import SimpleModalDialog from '../../../../../shared/simpleDialog';
import { DialogContext } from '../../../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../../../store/contexts/orderContext';
import dialogActions from '../../../../../../store/actions/dialogActions';
import { ApiTimeOut } from '../../../../../../constants/dialog.const';
import Geo from '../../../../../../constants/geos.const';
import translations from './createRfo.i18n';
import useGetOrderDetails from '../../../../../../hooks/useGetOrderDetails';
import useHasPermission from '../../../../../../hooks/useHasPermission';
import { orderClassifications } from '../../../../../../constants/order.const';

import {
  CreatePhysicalRfo,
  CreateDigitalRfo,
  RetailCreatePhysicalRfo,
  RetailCreateDigitalRfo,
} from '../../../../../../constants/permissions.const';
import { getValue } from '../../../../../../utils/browserStorage';

const CreateRfo = ({ isDialogOpen, setDialogOpen }) => {
  const classes = useStyles();
  const { hasPermission, hasAllOfPermissions } = useHasPermission();
  const { setSnack, setError } = useSnacks();
  const { reset } = dialogActions;
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);
  const { address } = dialogState;
  const {
    address1,
    address2,
    city,
    state,
    country,
    postalCode,
    email,
    firstName,
    lastName,
    alternateFirstName,
    alternateLastName,
    dayPhoneNumber,
  } = address;
  const {
    DIGITAL_GIFT_CARD_RFO_LABEL,
    PHYSICAL_GIFT_CARD_RFO_LABEL,
    RETURN_AS_NEW_GIFT_CARD_ERROR,
    RETURN_AS_NEW_GIFT_CARD_SUCCESS,
    RFO_TAKES_TIME,
    ADD_RFO_TITLE,
    APPLY,
    CANCEL,
  } = useMemoTranslations(translations);
  const {
    omsRegionReference: region,
    orderHeaderKey,
    orderNumber,
    billTo,
    orderClassification,
  } = orderDetail;

  const isStoreOrder = orderClassification === orderClassifications.STORE;
  const [showDigital, setShowDigital] = useState(true);
  const bothRFOPermissions = hasAllOfPermissions(
    !isStoreOrder
      ? [CreatePhysicalRfo, CreateDigitalRfo]
      : [RetailCreatePhysicalRfo, RetailCreateDigitalRfo]
  );

  const showOptions = ![Geo.JAPAN, Geo.EUROPE].includes(region) && bothRFOPermissions;
  const showOnlyPhysical =
    hasPermission(!isStoreOrder ? CreatePhysicalRfo : RetailCreatePhysicalRfo) &&
    !hasPermission(!isStoreOrder ? CreateDigitalRfo : RetailCreateDigitalRfo);

  const { runQueryOrderDetails } = useGetOrderDetails({
    followUpOnError: ({ error }) =>
      setError(`${RETURN_AS_NEW_GIFT_CARD_SUCCESS}. ${error.message}`),
    followUpOnCompleted: () => {
      setSnack(`${RETURN_AS_NEW_GIFT_CARD_SUCCESS}. ${RFO_TAKES_TIME}`);
      setDialogOpen(false);
    },
  });

  const requiredFieldsFilled = () => {
    if (showDigital) {
      return firstName && lastName && email;
    } else {
      const addressValuesThatMustBeTruthy = [
        'firstName',
        'lastName',
        'address1',
        'city',
        'postalCode',
        'dayPhoneNumber',
      ];
      if (region === Geo.US) addressValuesThatMustBeTruthy.push('state');
      if (region === Geo.EUROPE) addressValuesThatMustBeTruthy.push('country');
      return addressValuesThatMustBeTruthy.every((property) => Boolean(address[property]));
    }
  };

  const RfoCardOptions = () => (
    <RadioGroup className={classes.radioSection} row>
      <FormControlLabel
        data-testid='rfo-digital-option'
        checked={showDigital}
        control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
        onChange={() => setShowDigital(true)}
        label={DIGITAL_GIFT_CARD_RFO_LABEL}
      />
      <span className={classes.horizontalSpacer} />
      <FormControlLabel
        data-testid='rfo-physical-option'
        checked={!showDigital}
        control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
        onChange={() => setShowDigital(false)}
        label={PHYSICAL_GIFT_CARD_RFO_LABEL}
      />
    </RadioGroup>
  );

  const [postReturnAsNewGiftCard, { loading: submitRFOLoading }] = useMutation(
    returnAsNewGiftCardMutation,
    {
      onError: (err) => {
        setError(`${RETURN_AS_NEW_GIFT_CARD_ERROR}: ${err.message}`);
      },
      onCompleted: (data) => {
        // error for initial post
        if (data?.postReturnAsNewGiftCard?.errors) {
          setError(
            `${RETURN_AS_NEW_GIFT_CARD_ERROR}: ${data.postReturnAsNewGiftCard.errors?.[0]?.error?.message}`
          );
          // error for handleCQRSAPI job status get
        } else if (data?.postReturnAsNewGiftCard?.error) {
          setError(
            `${RETURN_AS_NEW_GIFT_CARD_ERROR}: ${data.postReturnAsNewGiftCard.error?.message}`
          );
        } else {
          runQueryOrderDetails({ orderNumber, isFollowUp: true });
        }
      },
    }
  );

  const handleSubmit = async () => {
    const orderNumber = orderDetail.orderNumber;
    const giftCardType = showDigital ? 'EGC' : 'GC';
    const parentSalesOrder = getValue('parentSalesOrder');
    const shipToCountry = parentSalesOrder?.orderLines?.[0]?.shipTo?.address?.country;
    const giftCardDetails = {
      request: {
        requestId: `${uuidv4()}`,
        giftCardType: giftCardType,
        status: 'ACTIVE',
        orderHeaderKey: orderHeaderKey,
        personInfo: {
          address: {
            addressLine1: address1,
            addressLine2: address2,
            state: state,
            zipCode: postalCode,
            country: region === Geo.EUROPE ? country : shipToCountry || billTo?.address?.country,
            city: city,
          },
          contactInformation: {
            dayPhone: dayPhoneNumber,
            email: email,
          },
          recipient: {
            firstName: firstName,
            lastName: lastName,
            kanaFirstName: alternateFirstName,
            kanaLastName: alternateLastName,
          },
        },
      },
    };
    postReturnAsNewGiftCard({ variables: { orderNumber, giftCardDetails, timeout: ApiTimeOut } });
  };

  useEffect(() => {
    // reset input fields on close
    if (!isDialogOpen) dialogDispatch(reset());
  }, [isDialogOpen]);

  useEffect(() => {
    if (region === Geo.EUROPE || showOnlyPhysical) setShowDigital(false);
  }, [region, showOnlyPhysical]);

  return (
    <SimpleModalDialog
      setIsOpen={setDialogOpen}
      isOpen={isDialogOpen}
      title={ADD_RFO_TITLE}
      manualClose={true}
      cancelLabel={CANCEL}
      confirmOptions={{
        label: APPLY,
        action: () => handleSubmit(),
        disabled: !requiredFieldsFilled(),
      }}
      content={
        <FormControl className={classes.form} data-testid={'rfo-form'}>
          {showOptions && <RfoCardOptions />}
          {showDigital && (
            <NameAndEmailForm
              region={region}
              variant={'outlined'}
              className={classes.addressForm}
            />
          )}
          {/* slight padding so TextField label doesn't get cut off in Europe form */}
          {region === Geo.EUROPE && <span className={classes.verticalSpacer} />}
          {!showDigital && (
            <AddressForm region={region} variant={'outlined'} className={classes.addressForm} />
          )}
          {submitRFOLoading && <Loading />}
        </FormControl>
      }
    />
  );
};

CreateRfo.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  radioSection: {
    marginBottom: spacing(2),
  },
  radio: {
    '&$checked': {
      color: palette.common.black,
    },
  },
  checked: {},
  verticalSpacer: {
    marginTop: '0.5rem',
  },
  horizontalSpacer: {
    marginLeft: '2rem',
  },
  addressForm: {
    gridGap: '1.5rem 1.5rem !important',
    // without important sometimes gridGap will not override default in AddressForm component
    // how much do we hate it?
  },
  form: {
    width: '100%',
  },
}));

export default CreateRfo;
