/* React/Utils */
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

/* Material-UI */
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import StoreOrdersPaymentDetails from './storeOrdersPaymentDetails.js';
/* Local */
import AddPaymentMethodDialog from './addPaymentMethodDialog';
import PaymentDetails from './paymentDetails';
import translations from '../payments.i18n';
import ErrorBoundary from '../../../../error/errorBoundary';
import BasicTableRow from '../../../../shared/table/tableRow';
import { FormattedCurrency } from '../../../../shared/formatCurrency';
import HasPermission from '../../../../shared/hasPermission';
import OrderContext from '../../../../../store/contexts/orderContext';
import { ApiTimeOut } from '../../../../../constants/dialog.const';
import Geo from '../../../../../constants/geos.const';
import { Partners } from '../../../../../constants/origin.const';
import { PaymentServiceMethodsDict } from '../../../../../constants/paymentTypes.const';
import { orderTypes, orderClassifications } from '../../../../../constants/order.const';
import {
  SubmitPayment,
  CreatePhysicalRfo,
  CreateDigitalRfo,
  AddPaymentMethod,
  RetailViewPaymentDetails,
  RetailSubmitPayment,
  RetailCreateDigitalRfo,
  RetailCreatePhysicalRfo,
} from '../../../../../constants/permissions.const';
import { useHistoryPushWithSessionId } from '../../../../../hooks/useHistorySessionId.js';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import useSnacks from '../../../../../hooks/useSnacks';
import SUBMIT_PAYMENT_MUTATION from '../../../../../mutations/submitPayment.mutation';
import ORDER_DETAIL_QUERY from '../../../../../queries/orderDetail.query';
import ORDER_SUMMARY_QUERY from '../../../../../queries/orderSummary.query';
import PAYMENT_DETAILS_QUERY from '../../../../../queries/paymentDetails.query';
import RETAIL_STORE_PAYMENT_DETAILS_QUERY from '../../../../../queries/paymentDetailsForStoreOrders.query.js';
import { prepareAddress } from '../../../../../utils/address';
import { format } from '../../../../../utils/date';
import getArrayProperty from '../../../../../utils/getArrayProperty';
import { calculateTotalTransferInAndOut, getRfoDetails } from '../../../../../utils/payment';
import CreateRfo from './createRFO/createRfo';
import TransactionRow from './transactionRow';
import GcOrderLink from '../../shared/gcOrderLink';
import useHasPermission from '../../../../../hooks/useHasPermission';

/*
 * This component is largely a duplicate of component in ./index.js, but with updates for using
 * payment details service. Eventually we should be able to use only one of these two, but
 * for testing purposes while we wait for payment details to be fully operational, we have
 * two that show up at different urls. This one is protected by developer permissions in
 * multiple spots, so athletes should 1) not be able to get to it and 2) won't make a call
 * to payments service that may throw an error message
 */
export default function CloudPayments() {
  const [orderInvoices, setOrderInvoices] = useState([]);
  const location = useLocation();
  const classes = useStyles();
  const { hasPermission } = useHasPermission();

  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  const {
    channel,
    currency,
    locale,
    omsRegionReference,
    orderClassification,
    orderNumber,
    orderType,
    parentReturnOrder,
    paymentMethods,
  } = orderDetail;

  const { setLoading, setStopLoading, setError, setSnack } = useSnacks();
  const setRoute = useHistoryPushWithSessionId();

  const isReturnOrder = orderType === orderTypes.RETURN;
  const isStoreOrder = orderClassification === orderClassifications.STORE;
  const isRfoOrder = orderClassification === orderClassifications.REFUND;
  const { CREDIT_CARD } = PaymentServiceMethodsDict;
  const {
    ADD_RFO,
    PAYMENT_SUMMARY,
    AMOUNT,
    AUTH_DATE,
    AUTH_NUM,
    ALL_TRANS,
    DATE,
    INVOICES,
    PAYMENT_DETAILS,
    STATUS,
    TOTALS,
    TOTAL_TRANSF_IN,
    TOTAL_TRANSF_OUT,
    TRANSF_DATE,
    ARIA_TRANS_DETAILS,
    ARIA_TRANS_TOTALS,
    TYPE,
    TRANSACTIONS,
    SUBMIT_PAYMENT,
    ADD_PAYMENT,
    REFUND,
    REFUNDED_TO,
    NEW_GC,
    RFO_ORDER_NUMBER,
    OG_PAYMENT,
    SUBMIT_PAYMENT_ERROR,
    SUBMIT_PAYMENT_SUCCESS,
    ARIA_SUBMIT_PAYMENT_LOADING,
    PAYMENT_DETAILS_ERROR,
    NO_TRANSACTIONS,
    LEGACY_PAYMENTS_WITH_ORDER,
    GO_TO_CSP_PAYMENTS_TAB,
  } = useMemoTranslations(translations);

  const [paymentDetails, setPaymentDetails] = useState({});
  const [storePaymentDetails, setStorePaymentDetails] = useState([]);
  const [activeCC, setActiveCC] = useState({});
  const [orderHeaderToGcOrderNumber, setOrderHeaderToGcOrderNumber] = useState({});
  const [isAddPaymentOpen, setAddPaymentOpen] = useState(false);
  const [isRfoModalOpen, setRfoModalOpen] = useState(false);
  const [rfoDetailsArray, setRfoDetailsArray] = useState([]);

  // array of order payment methods from payment service
  const { orderPaymentDetails } = paymentDetails;

  // billing info, order info from payment details service
  const { billingAddress } = paymentDetails;

  const chargeTransactionDetails = getArrayProperty(orderDetail, 'chargeTransactionDetails');
  const totalTransferAmounts = calculateTotalTransferInAndOut(chargeTransactionDetails);

  // header values for transactions table
  const transactionsHeaders = ['', DATE, AUTH_DATE, TYPE, AMOUNT, AUTH_NUM, STATUS, INVOICES];

  const partner = channel ? Partners[channel] : '';

  const rfoPermissions = [CreatePhysicalRfo, CreateDigitalRfo];
  const retailRFOPermissions = [RetailCreatePhysicalRfo, RetailCreateDigitalRfo];
  /*
    Add Payment & Add RFO buttons receive the same display logic:
      - needed on sales orders, not returns or exchanges
      - needed in NA, EMEA, JAPAN
  */
  const showAddButtons =
    !parentReturnOrder &&
    !isRfoOrder &&
    orderType === orderTypes.SALES &&
    ![Geo.KOREA, Geo.NIKEGS, Geo.NIKEXA, Geo.CHINA].includes(omsRegionReference);

  /*
    Submit Payment button receives its own logic
      - needed on sales, return, and exchange orders
      - needed in NA, EMEA, JAPAN, and CHINA
  */
  const showSubmitButton =
    !isRfoOrder &&
    [orderTypes.SALES, orderTypes.RETURN].includes(orderType) &&
    ![Geo.KOREA, Geo.NIKEGS, Geo.NIKEXA, Geo.NIKEHK].includes(omsRegionReference);

  const handleOrderClick = (orderNumber, page) => {
    setRoute(`/order/${orderNumber}/${page}`);
  };

  // method to re-fetch orderDetails after any modifying operation
  const reFetchOrderDetails = () => {
    queryOrderDetails({
      variables: {
        orderNumber,
      },
    });
  };

  useEffect(() => {
    if (orderDetail) {
      const rfoDetailsArray = getRfoDetails(chargeTransactionDetails);
      setRfoDetailsArray(rfoDetailsArray);
      if (rfoDetailsArray.length) {
        const headerKeyCommaSeparatedList = rfoDetailsArray
          .map((a) => a.rfoGcOrderHeaderKey)
          .join(',');
        queryOrderSummary({
          variables: {
            filters: [{ key: 'orderHeaderKey', value: headerKeyCommaSeparatedList }],
          },
        });
      }
    }
  }, [orderDetail]);

  // Function to trigger submitPayment call
  const handleSubmitPayment = () => {
    submitPayment({
      variables: {
        input: { request: { requestId: uuidv4() } },
        orderNumber,
        timeout: ApiTimeOut,
      },
    });
  };

  // Mutation that calls Modify Price
  const [submitPayment, { loading: submitPaymentLoading }] = useMutation(SUBMIT_PAYMENT_MUTATION, {
    onError: (err) => {
      setError(`${SUBMIT_PAYMENT_ERROR} ${err.message}`);
    },
    onCompleted: (response) => {
      setSnack(`${SUBMIT_PAYMENT_SUCCESS}`);
      reFetchOrderDetails();
    },
  });

  // Queries order details
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: () => {
      setError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      const { orderDetail } = queryOrderDetailsData;
      setOrderDetail(orderDetail);
    },
  });

  // query order summary if we need to link to new gift card order (RFO)
  const [
    queryOrderSummary,
    { data: orderSummaryData, loading: orderSummaryLoading, error: errorFromOrderSummary },
  ] = useLazyQuery(ORDER_SUMMARY_QUERY, {
    onError: () => {
      setError(errorFromOrderSummary.message);
    },
    onCompleted: () => {
      const newOrderHeaderToGcOrderNumberMap = {};
      orderSummaryData?.csOrderSummary?.objects?.forEach((obj) => {
        newOrderHeaderToGcOrderNumberMap[obj.orderHeaderKey] = obj.orderNumber;
      });
      setOrderHeaderToGcOrderNumber(newOrderHeaderToGcOrderNumberMap);
    },
  });

  // queries payment details
  const [queryPaymentDetails, { data, error }] = useLazyQuery(PAYMENT_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setError(`${PAYMENT_DETAILS_ERROR} ${error.message}`);
    },
    onCompleted: () => {
      /* 
        This sorting ensures that when more than one CC payment method
        exists on an order, the first CC listed is the active one, per
        athletes' request.
      */
      data.paymentDetails.orderPaymentDetails.sort((a, b) => {
        const aType = a?.orderPayment?.paymentMethod;
        const bType = b?.orderPayment?.paymentMethod;
        const aStatus = a?.orderPayment?.paymentStatus;
        const bStatus = b?.orderPayment?.paymentStatus;
        if (aType === bType) {
          if (aStatus === bStatus) return 0;
          if (aStatus === 'ACTIVE' && bStatus !== 'ACTIVE') {
            return -1;
          }
          if (aStatus !== 'ACTIVE' && bStatus === 'ACTIVE') {
            return 1;
          }
          return 0;
        }
        if (aType === CREDIT_CARD && bType !== CREDIT_CARD) return -1;
        return 1;
      });

      setStopLoading();
      setPaymentDetails(data.paymentDetails);
      setActiveCC(findActiveCC(data.paymentDetails.orderPaymentDetails));
    },
  });

  const [
    queryPaymentDetailsForStoreOrders,
    { data: storePayment, error: storeError },
  ] = useLazyQuery(RETAIL_STORE_PAYMENT_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setError(`${PAYMENT_DETAILS_ERROR} ${storeError.message}`);
    },
    onCompleted: () => {
      /*
        This sorting ensures that when more than one CC payment method
        exists on an order, the first CC listed is the active one, per
        athletes' request.
      */
      setStopLoading();
      setStorePaymentDetails(storePayment.retailStorePaymentDetails.tenderTypes);
    },
  });

  const updatePaymentDetails = () => {
    !isStoreOrder
      ? queryPaymentDetails({ variables: { orderNumber } })
      : queryPaymentDetailsForStoreOrders({ variables: { orderNumber } });
  };

  useEffect(() => {
    if (
      location.pathname.includes('/paymentDetails') &&
      orderNumber &&
      Object.keys(paymentDetails).length === 0 &&
      // do not query for orders of types return or store.
      !isReturnOrder
    ) {
      if (!isStoreOrder) {
        setLoading();
        queryPaymentDetails({
          variables: { orderNumber },
        });
      } else {
        if (hasPermission(RetailViewPaymentDetails)) {
          setLoading();
          queryPaymentDetailsForStoreOrders({
            variables: { orderNumber },
          });
        }
      }
    }
  }, [location.pathname, orderNumber]);

  /**
   * Find Active Credit Card
   *
   * @param {array} orderPaymentDetails
   * @return {object} Object with two properties: paymentId & accountNumber of active CC
   */
  const findActiveCC = (orderPaymentDetails) => {
    const existingActiveCC = orderPaymentDetails.find(
      (payment) =>
        payment.orderPayment.paymentMethod === CREDIT_CARD &&
        payment.orderPayment.paymentStatus === 'ACTIVE'
    );

    if (existingActiveCC) {
      return {
        paymentId: existingActiveCC?.orderPayment?.paymentId,
        accountNumber: existingActiveCC?.creditCardInfo?.accountNumber,
      };
    } else return {};
  };

  // If there exist a payment method in om, that is not in payments, show the flag
  const cspPaymentMethods = paymentMethods?.map((paymentMethod) => paymentMethod.paymentId);
  const cloudPaymentMethods = orderPaymentDetails?.map((opd) => opd.orderPayment.paymentId);
  const displayLegacyPaymentsWarning =
    cspPaymentMethods &&
    cloudPaymentMethods &&
    !cspPaymentMethods.every((cpm) => cloudPaymentMethods.includes(cpm));

  const TransactionAccordion = ({ paymentId, transactionRows, classes }) => (
    <Accordion
      elevation={0}
      classes={{ root: classes.accordionRoot }}
      data-testid={'transaction-accordion'}>
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls={`payment-transactions-${paymentId}`}
        classes={{
          root: classes.accordionSummary,
          expanded: classes.accordionSummaryExpanded,
          content: classes.accordionSummaryContent,
          expandIcon: classes.accordionExpandIcon,
        }}>
        <Typography
          aria-label={TRANSACTIONS.toLowerCase()}
          variant='subtitle1'
          component='h3'
          className={classes.cardSubHeading}>
          {TRANSACTIONS}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Table aria-label={ARIA_TRANS_DETAILS}>
          <TableHead>
            <BasicTableRow header data={transactionsHeaders} cellClassName={classes.tableHeader} />
          </TableHead>
          <TableBody>{transactionRows}</TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );

  const NoTransactionsBox = () => (
    <Box style={{ display: 'flex', minHeight: '48px', padding: '0 16px' }}>
      <Box style={{ display: 'flex', flexGrow: '1', margin: '12px 0' }}>
        <Typography style={{ fontSize: '1rem' }} variant={'h3'}>
          {NO_TRANSACTIONS}
        </Typography>
      </Box>
    </Box>
  );

  const generateTransactionRows = (transactions, orderInvoices, setOrderInvoices, paymentId) =>
    transactions.map((chargeDetail, k) => (
      <TransactionRow
        chargeDetail={chargeDetail}
        index={k}
        key={`payment-transaction-${paymentId}-${k}`}
        customKey={`payment-transaction-${paymentId}-${k}`}
        orderInvoicesState={orderInvoices}
        setOrderInvoices={setOrderInvoices}
      />
    ));

  return (
    <ErrorBoundary>
      <h1 className='accessibly-hidden'>{PAYMENT_DETAILS}</h1>
      <Typography variant='h5' component='h2' className={classes.sectionTitle}>
        {PAYMENT_SUMMARY}
      </Typography>
      <Paper data-testid={'payments-cont'} classes={{ root: classes.sectionWrapper }}>
        <Box
          className={classes.totals}
          aria-label={ARIA_TRANS_TOTALS}
          data-testid='total-transfers'>
          <Typography
            variant='subtitle1'
            component='h3'
            className={classes.cardHeaderTitle}
            aria-label={TOTALS.toLowerCase()}>
            {TOTALS}
          </Typography>
          <Typography component='span' variant='body2' className={classes.cardHeaderSubtitle}>
            {`${TOTAL_TRANSF_IN}: `}
            <FormattedCurrency amount={totalTransferAmounts.in} currency={currency} />
          </Typography>
          <Typography component='span' variant='body2' className={classes.cardHeaderSubtitle}>
            {`${TOTAL_TRANSF_OUT}: `}
            <FormattedCurrency amount={totalTransferAmounts.out} currency={currency} />
          </Typography>
          {/* if refunded to new gift card show transfer out date */}
          {isReturnOrder && Boolean(rfoDetailsArray.length) && (
            <Typography component='span' variant='body2' className={classes.cardHeaderSubtitle}>
              {`${TRANSF_DATE}: ${format(rfoDetailsArray[0].rfoDate, false, locale)}`}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box className={classes.transactionDetailsWrapper} data-testid={'order-transactions'}>
          {!isReturnOrder ? (
            <>
              <Box className={classes.transactionDetails}>
                <Typography
                  aria-label={ALL_TRANS.toLowerCase()}
                  variant='subtitle1'
                  component='h3'
                  className={classes.cardHeaderTitle}>
                  {ALL_TRANS}
                </Typography>
              </Box>
              <Table aria-label={ARIA_TRANS_DETAILS}>
                <TableHead>
                  <BasicTableRow
                    header
                    data={transactionsHeaders}
                    cellClassName={classes.tableHeader}
                  />
                </TableHead>
                <TableBody key='order-transactions'>
                  {Array.isArray(chargeTransactionDetails) &&
                    chargeTransactionDetails.map((chargeDetail, i) => {
                      return (
                        <TransactionRow
                          chargeDetail={chargeDetail}
                          index={i}
                          key={'order-transaction-' + i}
                          customKey={'order-transaction-' + i}
                          orderInvoicesState={orderInvoices}
                          setOrderInvoices={setOrderInvoices}
                          GcOrderLink={() => (
                            <GcOrderLink
                              isLoading={orderSummaryLoading}
                              gcOrderNumber={
                                orderHeaderToGcOrderNumber[chargeDetail.transferToOrderHeaderKey]
                              }
                            />
                          )}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </>
          ) : (
            <Box className={classes.transactionDetails}>
              <Typography
                aria-label={REFUND.toLowerCase()}
                variant='subtitle1'
                component='h3'
                className={classes.cardHeaderTitle}>
                {REFUND}
              </Typography>
              <Typography
                variant='body2'
                data-testid='refunded-to'
                className={classes.cardHeaderSubtitle}>
                {
                  <>
                    {`${REFUNDED_TO}: `}
                    {Boolean(rfoDetailsArray.length) ? (
                      <>
                        {`${NEW_GC}\t\t${RFO_ORDER_NUMBER}: `}
                        <GcOrderLink
                          isLoading={orderSummaryLoading}
                          gcOrderNumber={
                            orderHeaderToGcOrderNumber[rfoDetailsArray[0].rfoGcOrderHeaderKey]
                          }
                        />
                      </>
                    ) : (
                      `${OG_PAYMENT}`
                    )}
                  </>
                }
              </Typography>
              {Boolean(rfoDetailsArray.length) && (
                <Box aria-label={REFUNDED_TO} data-testid='refund-details'>
                  <Typography
                    variant='body2'
                    data-testid='refunded-to-contact-name'
                    className={classes.refundedToContact}>
                    {`${orderDetail?.billTo?.recipient?.firstName || '--'} ${orderDetail?.billTo
                      ?.recipient?.lastName || '--'}`}
                  </Typography>
                  {omsRegionReference === Geo.JAPAN ? (
                    <Typography
                      variant='body2'
                      data-testid='refunded-to-contact-email'
                      className={classes.refundedToContact}>
                      {orderDetail?.billTo?.contactInformation.email || '--'}
                    </Typography>
                  ) : (
                    <Typography
                      variant='body2'
                      data-testid='refunded-to-contact-address'
                      className={classes.refundedToAddress}>
                      {prepareAddress(orderDetail).join('\n')}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Paper>
      {displayLegacyPaymentsWarning && (
        <div className={classes.legacyPaymentWarning} data-testid={'legacy-payment-warning'}>
          <ErrorOutlineIcon fontSize='default' color='error' />
          <Typography className={classes.textPadding}>{LEGACY_PAYMENTS_WITH_ORDER}</Typography>
          <Typography
            onClick={() => handleOrderClick(orderNumber, 'cspPayments')}
            className={classes.boldLink}>
            {GO_TO_CSP_PAYMENTS_TAB}
          </Typography>
        </div>
      )}
      {!isStoreOrder &&
        orderPaymentDetails &&
        orderPaymentDetails.map((payment, i) => {
          const paymentId = payment.orderPayment.paymentId;
          const thisPaymentTransactions = chargeTransactionDetails.filter(
            (orderTransaction) => orderTransaction.paymentId === paymentId
          );

          const transactionRows = generateTransactionRows(
            thisPaymentTransactions,
            orderInvoices,
            setOrderInvoices,
            paymentId
          );

          return (
            <React.Fragment key={i}>
              <PaymentDetails
                index={i}
                cardClass={classes.sectionWrapper}
                tableHeadingClass={classes.tableHeader}
                cellClass={classes.tableCell}
                payment={payment}
                billingAddress={billingAddress}
                orderNumber={orderNumber}
                orderType={orderType}
                parentReturnOrder={parentReturnOrder}
                partner={partner}
                region={omsRegionReference}
                updatePaymentDetails={updatePaymentDetails}
                activeCC={activeCC}>
                {transactionRows.length > 0 ? (
                  <TransactionAccordion
                    paymentId={paymentId}
                    transactionRows={transactionRows}
                    classes={classes}
                  />
                ) : (
                  <NoTransactionsBox />
                )}
              </PaymentDetails>
            </React.Fragment>
          );
        })}

      {isStoreOrder &&
        storePaymentDetails &&
        storePaymentDetails.map((payment, i) => {
          const tenderUUID = payment.tenderUUID;
          const thisPaymentTransactions = chargeTransactionDetails.filter(
            (orderTransaction) => orderTransaction.paymentId === tenderUUID
          );
          const transactionRows = generateTransactionRows(
            thisPaymentTransactions,
            orderInvoices,
            setOrderInvoices,
            tenderUUID
          );

          return (
            <React.Fragment key={i}>
              <StoreOrdersPaymentDetails
                index={i}
                cardClass={classes.sectionWrapper}
                tableHeadingClass={classes.tableHeader}
                cellClass={classes.tableCell}
                payment={payment}
                orderNumber={orderNumber}
                orderType={orderType}
                parentReturnOrder={parentReturnOrder}
                partner={partner}
                region={omsRegionReference}
                updatePaymentDetails={updatePaymentDetails}
                isStoreOrder={isStoreOrder}>
                {transactionRows.length > 0 ? (
                  <TransactionAccordion
                    paymentId={tenderUUID}
                    transactionRows={transactionRows}
                    classes={classes}
                  />
                ) : (
                  <NoTransactionsBox />
                )}
              </StoreOrdersPaymentDetails>
            </React.Fragment>
          );
        })}
      {/* Hide submit/add buttons if Geo is KOREA or it's a partner order */}
      {omsRegionReference !== Geo.KOREA && !partner && (
        <Box className={classes.actionButtonsContainer}>
          {showAddButtons && (
            <>
              {!isStoreOrder && (
                <HasPermission permission={AddPaymentMethod}>
                  <Button
                    onClick={() => setAddPaymentOpen(true)}
                    target='_blank'
                    aria-label={ADD_PAYMENT.toLowerCase()}
                    data-testid='add-payment-button'
                    className={classes.secondaryButton}
                    startIcon={<AddIcon />}>
                    {ADD_PAYMENT}
                  </Button>
                  {/* Add New Payment Method modal */}
                  <AddPaymentMethodDialog
                    activeCC={activeCC}
                    updatePaymentMethods={updatePaymentDetails}
                    isDialogOpen={isAddPaymentOpen}
                    setDialogOpen={setAddPaymentOpen}
                  />
                </HasPermission>
              )}
              <HasPermission anyOf={!isStoreOrder ? rfoPermissions : retailRFOPermissions}>
                <Button
                  color='default'
                  onClick={() => setRfoModalOpen(true)}
                  aria-label={ADD_RFO.toLowerCase()}
                  startIcon={<AddIcon />}
                  className={classes.secondaryButton}
                  data-testid='add-rfo-button'>
                  {ADD_RFO}
                </Button>
                {/* Create RFO Modal */}
                <CreateRfo isDialogOpen={isRfoModalOpen} setDialogOpen={setRfoModalOpen} />
              </HasPermission>
            </>
          )}
          {showSubmitButton && (
            <HasPermission permission={!isStoreOrder ? SubmitPayment : RetailSubmitPayment}>
              <Button
                target='_blank'
                aria-label={SUBMIT_PAYMENT.toLowerCase()}
                onClick={handleSubmitPayment}
                data-testid='submit-payment-button'
                color='primary'
                variant='contained'
                className={classes.submitButton}>
                {SUBMIT_PAYMENT}
              </Button>
            </HasPermission>
          )}
          {submitPaymentLoading && <CircularProgress aria-label={ARIA_SUBMIT_PAYMENT_LOADING} />}
        </Box>
      )}
    </ErrorBoundary>
  );
}

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    padding: 0,
    margin: 0,
  },
  cardHeaderSubtitle: {
    padding: 0,
    paddingRight: theme.spacing(1.2),
    whiteSpace: 'pre',
  },
  transparent: {
    backgroundColor: theme.palette.grey[100],
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875),
  },
  secondaryButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[350],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '24px',
    fontSize: '1rem',
    lineHeight: 1.5,
    textTransform: 'unset', // override MUI all-caps default
    padding: `${theme.spacing(0.875)}px ${theme.spacing(2.5)}px`,
    marginLeft: theme.spacing(1.5),
  },
  submitButton: {
    borderRadius: '24px',
    fontSize: '1rem',
    lineHeight: 1.5,
    textTransform: 'unset', // override MUI all-caps default
    padding: `${theme.spacing(0.875)}px ${theme.spacing(2.5)}px`,
    marginLeft: theme.spacing(1.5),
  },
  refundedToContact: {
    paddingLeft: theme.spacing(1.2),
    lineHeight: '1.7',
  },
  refundedToAddress: {
    paddingLeft: theme.spacing(1.2),
    verticalAlign: 'top',
    whiteSpace: 'pre',
  },
  totals: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.25)}px ${theme.spacing(0.5)}px`,
  },
  transactionDetails: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px`,
  },
  transactionDetailsWrapper: {
    padding: 0,
    paddingBottom: `${theme.spacing(1.25)}px`,
  },
  cardHeaderTitle: {
    fontWeight: 500,
    lineHeight: 1.5,
    marginBottom: `${theme.spacing(0.5)}px`,
  },
  sectionWrapper: {
    'padding': 0,
    '&:last-child': {
      paddingBottom: theme.spacing(1.25),
    },
    'marginBottom': `${theme.spacing(0.625)}px`,
    'box-shadow': '-1px 4px 10px rgba(0, 0, 0, 0.05)',
  },
  sectionTitle: {
    margin: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.2,
    padding: `${theme.spacing(2.5)}px 0 ${theme.spacing(1.875)}px`,
    color: theme.palette.text.primary,
  },
  tableCell: {
    padding: theme.spacing(1.2),
  },
  tableHeader: {
    margin: 0,
    padding: theme.spacing(1.25),
  },
  carrot: {
    color: 'rgb(55,141,248)',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(1),
  },
  loaderMargin: {
    margin: theme.spacing(2.5),
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  textPadding: {
    paddingLeft: '12.25px',
    paddingRight: '10px',
  },
  boldLink: {
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    padding: '0',
  },
  legacyPaymentWarning: {
    display: 'flex',
    width: '100%',
    background: '#D6EEFF',
    height: '50px',
    alignItems: 'center',
    paddingLeft: '12.25px',
  },
}));
